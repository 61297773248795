import { createSlice } from "@reduxjs/toolkit";
// import { RoutesMap } from "../../map/routeMap";

export interface shopState {
    shopName:string;
    shopId:string,
    logo:string
}

const initialState: shopState={
    shopName:"",
    shopId:"",
    logo:""
}
export const shoperSlice = createSlice({
    name: "shoper",
    initialState, //名字一定要是initialState
    reducers: {
        changeShopslice:(state,{payload}) =>{
            // console.log(payload)
           state.shopId = payload.value.shopId
           state.shopName = payload.value.shopName
           state.logo = payload.value.logo
        },
        initShopslice:(state,{payload}) =>{
            state.shopId = payload.value.shopId
            state.shopName = payload.value.shopName
            state.logo = payload.value.logo
        }
    }
})

export const {initShopslice, changeShopslice} = shoperSlice.actions;

// 默认导出
export default shoperSlice.reducer;