import React from "react";
import "./index.sass"

//FC function
const LoadingRoute: React.FC = () => {
    return (
        <div className="loading-box">
            <div className="animation"></div>
            <div className="light"></div>
            <div className="light"></div>
            <div className="shadow"></div>
        </div>
    )
}
export default LoadingRoute
