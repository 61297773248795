import React, { useEffect } from "react";
import LoginForm from "../../components/LoginForm";
import {Image} from "antd"
import { useNavigate } from "react-router-dom";
import "./index.css"
import { baseUrl } from "../../utils/request";

//FC function
const Login: React.FC = () => {
    const navigate = useNavigate()
    useEffect(() => {
        // localStorage.clear()
        if (localStorage.getItem("isLogin") === '1') {
            navigate("/home/1/10/0")
        }
    }, [])
    return (
        <div className="login-page">
            <div className="left-image">
                <Image preview={false} width={550} src={baseUrl + "/img/1673510277800_586.png"}></Image>
            </div>
            <LoginForm></LoginForm>
        </div>
    )
}
export default Login
