import { createSlice } from "@reduxjs/toolkit";
// import { RoutesMap } from "../../map/routeMap";

export interface shopState {
    isLogin:string;
}

const initialState: shopState={
    isLogin:""
}
export const loginSlice = createSlice({
    name: "login",
    initialState, //名字一定要是initialState
    reducers: {
        changeLoginslice:(state,{payload}) =>{
            state.isLogin = payload.value.isLogin
        },
        initLoginslice:(state,{payload}) =>{
            state.isLogin = payload.value.isLogin
        }
    }
})

export const {initLoginslice, changeLoginslice} = loginSlice.actions;

// 默认导出
export default loginSlice.reducer;