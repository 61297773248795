
import React, { useRef, useState } from 'react';
import Layouts from './Layouts';
import Login from './views/Login';
import './App.scss';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';




const App: React.FC = () => {

  return (
    <div>
      <Login />
    </div>
  );
};

export default App;