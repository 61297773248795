
import { AxiosResponse } from "axios";
import {createAxiosByinterceptors} from "./interceptors"
import { message } from "antd";

export const getRequest = (url: string,params:any, loadding?: boolean) => {
    return new Promise<any>((resolve, reject) => {
        const request = createAxiosByinterceptors({})
        request({
            method:"GET",
            url:url,
            params
        }).then((res:any) => {
            // console.log(res.data)
            if(res.code === 10000){
                message.error("系统出差啦，请重试")
                return
            }
            if(res.message === "Request failed with status code 401"){
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            if(res.code !== 200 && res.code !== 0 && params?.responseType !== 'blob'){
                message.error(res.message)
            }
            // console.log(res)

            resolve(res)
        }).catch((err) =>{
            reject(err)
            message.error("系统错误请联系管理员")
        })
    })
}

// 一定要加new Promise<any>这样才能获取里面的数据，不然ts会报错

export const postRequest = (url: string, data:any) => {
    return new Promise<any>((resolve, reject) => {
        const request =createAxiosByinterceptors()
        request({
            method:"POST",
            url,
            data
        }).then((res:any) =>{
            if(res.code === 10000){
                message.error("系统出差啦，请重试")
                return
            }
            if(res.message === "Request failed with status code 401"){
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            if(res.code !== 200 && res.code !== 0){
                message.error(res.response.data.message)
            }
            resolve(res)
        }).catch((err) =>{
            reject(err)
            message.error("系统错误请联系管理员")
        })
    })
}

export const codeRequest = (url:string) => {
    return new Promise<any>((resolve, reject) => {
        const request =createAxiosByinterceptors()
        request({
            method:"POST",
            url,
            responseType: "arraybuffer"
        }).then((res:any) =>{
            resolve(res)
        }).catch((err) =>{
            reject(err)
            message.error("系统错误请联系管理员")
        })
    })
}

export const deleteRequest = (url: string) => {
    return new Promise<any>((resolve, reject) => {
        const request =createAxiosByinterceptors()
        request({
            method:"DELETE",
            url,
        }).then((res:any) =>{
            if(res.code === 10000){
                message.error("系统出差啦，请重试")
                return
            }
            if(res.message ==="Request failed with status code 401"){
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            if(res.code !== 200 && res.code !== 0){
                message.error(res.message)
            }
            resolve(res)
        }).catch((err) =>{
            reject(err)
            message.error("系统错误请联系管理员")
        })
    })
}

export const putRequest = (url: string) => {
    return new Promise<any>((resolve, reject) => {
        const request =createAxiosByinterceptors()
        request({
            method:"PUT",
            url,
        }).then((res:any) =>{
            if(res.code === 10000){
                message.error("系统出差啦，请重试")
                return
            }
            if(res.message ==="Request failed with status code 401"){
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = "/"
            }
            if(res.code !== 200 && res.code !== 0){
                message.error(res.message)
            }
            resolve(res)
        }).catch((err) =>{
            reject(err)
            message.error("系统错误请联系管理员")
        })
    })
}

export const baseUrl= "https://shop1.aday.top"