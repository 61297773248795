import axios, {AxiosInstance,AxiosRequestConfig} from "axios"


//AxiosRequestConfig config的类型
//AxiosInstance instance的类型
//创建axios拦截器
export const createAxiosByinterceptors = (
    config?:AxiosRequestConfig
):AxiosInstance => {
    const instance = axios.create({
        timeout:600 * 1000, //超时配置
        ...config, // 自定义配置
    })

    // 添加请求拦截
    instance.interceptors.request.use(
        (config:any) => {
            if(localStorage.getItem("token")){
                config.headers["Authorization"] = localStorage.getItem("token")
              }
            //   console.log( sessionStorage.getItem("token"))
          // config.headers["Authorization"] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJvZE8teDVPY0dvejlaX0tJenJHY3A0alIxX0k0IiwiaWF0IjoxNjcxODcwNTkwLCJleHAiOjE2NzI0NzUzOTB9.PqNFTuYfSoivnfhumWPT7MfHmvx8EBHegZmYr47IN5LV3NHFS7XmP2QhCEwbmCm7A-BjyIKL-1nePUatyCDDRQ"
            return config
        },
        (error) =>{
            return Promise.reject(error)
        }
    )

    //添加响应拦截
    instance.interceptors.response.use((res) => {
        if(res.data.status ==0) {
            return res.data
        }
        else if(res.data.status === 401){
            return res.data
        }else{
            return res.data
        }
    },(error) => {
        return error
    })



    return instance
}
